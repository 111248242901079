import ModelOptImg from '@/assets/model-option-prompt.png';
import LangImg from '@/assets/bot-creator-lang.png';
// import ExpandImg from "@/assets/bot-creator-prompt-expand.png";
// import CollapseImg from "@/assets/bot-creator-prompt-collapse.png";
import { ReactComponent as ExpandIcon } from '@/assets/bot-creator-prompt-expand.svg';
import { ReactComponent as CollapseIcon } from '@/assets/bot-creator-prompt-collapse.svg';

// import OptionSelected from '@/assets/settings-selected.png'
// import OptionUnSelected from '@/assets/settings-unselected.png'
// import OptionSelectedMust from '@/assets/settings-selected-must.png'
import { ReactComponent as OptionSelected } from '@/assets/check-icon-active.svg';
import { ReactComponent as OptionUnSelected } from '@/assets/check-icon-lm.svg';
import { ReactComponent as OptionSelectedMust } from '@/assets/settings-selected-must.svg';

import DropDown from './DropDown';
import './promptContainer.scoped.scss';
import { InputForm } from './InputForm';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as ScopeIcon } from '@/assets/scope-icon-lm.svg';
import { ReactComponent as PlusIcon } from '@/assets/plus-icon.svg';
import { ReactComponent as MinusIcon } from '@/assets/minus-icon.svg';
import { v4 as uuidv4 } from 'uuid';
import { AVALIBLE_MODELS_BOT_GENERATOR } from '@/constants/enums';

export const PromptContainer = ({
  promptKey,
  updatePromptSetting,
  setPromptInsideValue,
  chosenDocs,
  prompt: {
    title,
    isOpen,
    subTitle,
    model,
    content,
    is_selected,
    vectors,
    messageToUser,
    isConnectToDocs,
    isAnswerBasedOnBestGrade,
    inputConversationLanguage,
    inputVectorsLanguage,
    userInputLanguage,
    is_must,
  },
}) => {
  const setInputConversationLanguage = (option) => {
    updatePromptSetting(promptKey, 'inputConversationLanguage', option);
  };

  const setInputVectorsLanguage = (option) => {
    updatePromptSetting(promptKey, 'inputVectorsLanguage', option);
  };

  const setModelOptions = (option) => {
    updatePromptSetting(promptKey, 'model', option);
  };

  const setPromptValue = (ev) => {
    updatePromptSetting(promptKey, 'content', ev.target.value);
  };

  const setUserInputLanguage = (option) => {
    updatePromptSetting(promptKey, 'userInputLanguage', option);
  };

  const openPromptSetting = (promptKey, isOpen) => {
    if (isConnectToDocs && chosenDocs.length === 0) {
    } else {
      updatePromptSetting(promptKey, 'isOpen', isOpen);
    }
  };

  const updatePromptOpen = (promptKey, is_selected) => {
    if (isConnectToDocs && chosenDocs.length === 0) {
    } else {
      updatePromptSetting(promptKey, 'is_selected', is_selected);
    }
  };

  return (
    <div
      className="prompt-container"
      style={{ opacity: isConnectToDocs && chosenDocs.length === 0 ? 0.5 : 1 }}
    >
      <div className="message-title-container">
        <div className="title-and-image-select">
          {is_must && isConnectToDocs && chosenDocs.length !== 0 ? (
            // <img
            //   style={{ cursor: isConnectToDocs && chosenDocs.length === 0 ? 'default' : 'pointer' }}
            //   src={OptionSelectedMust}
            //   alt={'selected'}
            // />
            <OptionSelectedMust
              style={{
                cursor: isConnectToDocs && chosenDocs.length === 0 ? 'default' : 'pointer',
                height: '32px',
                width: '32px',
              }}
            />
          ) : (
            is_selected !== undefined && (
              <>
                {is_must ? (
                  <OptionSelectedMust
                    style={{
                      cursor: isConnectToDocs && chosenDocs.length === 0 ? 'default' : 'pointer',
                      width: '32px',
                      height: '32px',
                    }}
                    onClick={() => updatePromptOpen(promptKey, !is_selected)}
                  />
                ) : is_selected ? (
                  <OptionSelected
                    style={{
                      cursor: isConnectToDocs && chosenDocs.length === 0 ? 'default' : 'pointer',
                      width: '32px',
                      height: '32px',
                    }}
                    onClick={() => updatePromptOpen(promptKey, !is_selected)}
                  />
                ) : (
                  <OptionUnSelected
                    style={{
                      cursor: isConnectToDocs && chosenDocs.length === 0 ? 'default' : 'pointer',
                      width: '32px',
                      height: '32px',
                    }}
                    onClick={() => updatePromptOpen(promptKey, !is_selected)}
                  />
                )}
              </>
            )
          )}

          <h6>
            {title}
            <p>{subTitle}</p>
          </h6>
        </div>
        {isOpen ? (
          <CollapseIcon
            style={{ width: '24px', height: '24px' }}
            onClick={() => openPromptSetting(promptKey, !isOpen)}
          />
        ) : (
          <ExpandIcon
            style={{ width: '24px', height: '24px' }}
            onClick={() => openPromptSetting(promptKey, !isOpen)}
          />
        )}
        {/* <img
          src={isOpen ? CollapseImg : ExpandImg}
          alt="expand image"
          onClick={() => openPromptSetting(promptKey, !isOpen)}
        /> */}
      </div>

      {isOpen && <InputForm question={content} handleChange={setPromptValue} />}

      {isOpen && (
        <div className="model-options">
          <div className="single-setting">
            <div className="setting-title">
              <img src={ModelOptImg} alt="" />
              <p>Model</p>
            </div>
            <Select
              className="select-box-tool-box"
              size="small"
              id="model"
              value={model}
              label="model"
              name="model"
              onChange={({ target }) => setModelOptions(target.value)}
            >
              {Object.keys(AVALIBLE_MODELS_BOT_GENERATOR).map((option) => (
                <MenuItem key={uuidv4()} value={AVALIBLE_MODELS_BOT_GENERATOR[option]}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>

          {inputConversationLanguage && (
            <div className="single-setting">
              <div className="setting-title">
                <img src={LangImg} alt="" />
                <p>Input Conversation Language</p>
              </div>

              <Select
                className="select-box-tool-box"
                size="small"
                id="inputConversationLanguage"
                value={inputConversationLanguage}
                label="inputConversationLanguage"
                name="inputConversationLanguage"
                onChange={({ target }) => setInputConversationLanguage(target.value)}
              >
                {['Original language', 'To English'].map((option) => (
                  <MenuItem key={uuidv4()} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}

          {inputVectorsLanguage && (
            <div className="single-setting">
              <div className="setting-title">
                <img src={LangImg} alt="" />
                <p>Input Vectors Language</p>
              </div>

              <Select
                className="select-box-tool-box"
                size="small"
                id="inputVectorsLanguage"
                value={inputVectorsLanguage}
                label="inputVectorsLanguage"
                name="inputVectorsLanguage"
                onChange={({ target }) => setInputVectorsLanguage(target.value)}
              >
                {['Original language', 'To English'].map((option) => (
                  <MenuItem key={uuidv4()} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {userInputLanguage && (
            <div className="single-setting">
              <div className="setting-title">
                <img src={LangImg} alt="" />
                <p>User Input Language</p>
              </div>
              <Select
                className="select-box-tool-box"
                size="small"
                id="userInputLanguage"
                value={userInputLanguage}
                label="userInputLanguage"
                name="userInputLanguage"
                onChange={({ target }) => setUserInputLanguage(target.value)}
              >
                {['Original language', 'To English'].map((option) => (
                  <MenuItem key={uuidv4()} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
      )}
      {isOpen && vectors && (
        <div className="vectors-container">
          {/* <div className="input-container">
            Vectors Number{' '}
            <input
              value={vectors.vectorsNumber}
              onChange={(ev) =>
                setPromptInsideValue(ev.target.value, promptKey, 'vectors', 'vectorsNumber')
              }
              type="number"
              max={10}
              min={1}
            />
          </div> */}

          <span>Vectors Number</span>
          <div className="input-container">
            <MinusIcon
              onClick={() => {
                if (vectors.vectorsNumber > 1)
                  setPromptInsideValue(vectors.vectorsNumber - 1, promptKey, 'vectors', 'vectorsNumber');
              }}
              className="icon-clr"
            />
            <p className="number-container">{vectors.vectorsNumber}</p>
            <PlusIcon
              onClick={() => {
                // if (vectors.vectorsNumber < 10)
                setPromptInsideValue(vectors.vectorsNumber + 1, promptKey, 'vectors', 'vectorsNumber');
              }}
              className="icon-clr"
            />
          </div>

          <div className="input-container">
            Passing Grade{' '}
            <input
              onChange={(ev) =>
                setPromptInsideValue(ev.target.value, promptKey, 'vectors', 'passingGrade')
              }
              value={vectors.passingGrade}
              type="number"
              max={100}
              min={1}
            />
          </div>
        </div>
      )}

      {isOpen && isAnswerBasedOnBestGrade !== undefined && (
        <div className="toggle-answer-container">
          <div className={`highlight  ${isAnswerBasedOnBestGrade ? 'left' : 'right'}`} />
          <button
            className={`option ${isAnswerBasedOnBestGrade ? 'selected' : ''}`}
            onClick={() => updatePromptSetting(promptKey, 'isAnswerBasedOnBestGrade', true)}
          >
            Best Grade
          </button>
          <button
            className={`option ${!isAnswerBasedOnBestGrade ? 'selected' : ''}`}
            onClick={() => updatePromptSetting(promptKey, 'isAnswerBasedOnBestGrade', false)}
          >
            All Above Passing Grade
          </button>
        </div>
      )}

      {isOpen && messageToUser && (
        <div className="message-for-user-container">
          <p>{messageToUser.titleForFront}</p>
          <textarea
            name="first-message"
            value={messageToUser.content}
            onChange={(ev) =>
              setPromptInsideValue(ev.target.value, promptKey, 'messageToUser', 'content')
            }
            placeholder={messageToUser.placeHolder}
          />
        </div>
      )}
    </div>
  );
};
