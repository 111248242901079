import { useEffect, useRef, useState } from 'react';
import './mainPage.scoped.css';
import { Chat } from '@/components/Chat/Chat';
import * as XLSX from 'xlsx';
import { ReactComponent as DownloadIcon } from '@/assets/chat-download-icon.svg';
import DocumentsMain from '@/components/DocumentsTab/DocumentsMain/DocumentsMain';
import { NewHeader } from '@/components/NewHeader/NewHeader';
import { toolKitSettingsAtom } from '@/atoms/chat';
import { useAtom } from 'jotai';
import MainPanel from '@/components/RightPanel/MainPanel/MainPanel';
import { inputsAtoms } from '@/atoms/inputs';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as NewChatIcon } from '@/assets/new-chat-icon.svg';
import FilledBtn from '@/components/FilledBtn/FilledBtn';
import {
  userAtom,
  requestCancelTokenAtom,
  isloadingAtom,
  propertiesAtom,
  tabValueAtom,
} from '@/atoms/general';
import Cookies from 'js-cookie';
import PromptsMain from '@/components/PromptsTab/PromptsMain/PromptsMain';
import { BotCreatorToolKit } from '@/components/BotCreator/BotCreatorToolKit/BotCreatorToolKit';
import { AdminMain } from '@/components/AdminTab/AdminMain/AdminMain';
import VoiceMain from '@/components/VoiceTab/VoiceMain/VoiceMain';
import { socketIOService } from '../../atoms/general';
import BotsMain from '@/components/BotsTab/BotsMain/BotsMain';

const loggedUser = JSON.parse(sessionStorage.getItem('user'));

export const MainPage = () => {
  const chatRef = useRef();
  const historyRef = useRef();

  ///////////////atoms start
  const [properties] = useAtom(propertiesAtom);
  const [isLoading, setIsLoading] = useAtom(isloadingAtom);
  const [toolKitSettings] = useAtom(toolKitSettingsAtom);
  const [user, setUser] = useAtom(userAtom);
  const [requestCancelToken, setRequestCancelToken] = useAtom(requestCancelTokenAtom);
  const [inputs, setInputs] = useAtom(inputsAtoms);
  /////////////////atoms end
  const [isPageEnglish, setIsPageEnglish] = useState(loggedUser?.lang === 'hebrew' ? false : true);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [isOpenPopUpDoc, setIsOpenPopUpDoc] = useState(false);
  const [isCloseToolKit, setIsCloseToolKit] = useState(true);
  const [isOverflow, setIsOverflow] = useState(false);
  const [langFlowUrl, setLangFlowUrl] = useState('');
  // needs to be global ref
  const promptsToDb = useRef();
  const documentsToDb = useRef();
  /// end needs to be global ref
  const [isHeaderBurgerOpen, setIsHeaderBurgerOpen] = useState(false);
  const [tabValue, setTabValue] = useAtom(tabValueAtom);
  const [isOpenBotCreator, setIsOpenBotCreator] = useState(false);
  const prevModelRef = useRef();

  useEffect(() => {
    if (toolKitSettings.model === 'dall-e-3' || prevModelRef.current === 'dall-e-3') {
      setInputs([
        {
          role: 'user',
          value: '',
          isAnswer: false,
          isLike: null,
          comment: '',
          show: true,
          toType: false,
          key: uuidv4(),
          arrOfAnswer: null,
          files: [],
        },
      ]);
    }
    prevModelRef.current = toolKitSettings.model;
  }, [toolKitSettings.model]);

  useEffect(() => {
    setLangFlowUrl(properties.urls?.flow_url);
  });
  const handleIsCloseToolKit = () => {
    setIsCloseToolKit((prev) => {
      return !prev;
    });
  };
  const checkOverflow = (element) => {
    setIsOverflow(element.current.scrollHeight > element.current.clientHeight);
  };
  const openPopUp = (ev) => {
    ev.stopPropagation();
    setIsOpenPopUp(true);
  };
  const handleSubmit = () => {
    chatRef.current.generateAnswer();
  };
  const stopGenerate = async () => {
    if (requestCancelToken) {
      socketIOService.emit('stopMiddleware', Cookies.get('client-id'));
      requestCancelToken.cancel('canceled by the user');
    }
  };

  const openPopUpDoc = (ev) => {
    ev.stopPropagation();
    setIsOpenPopUpDoc(true);
  };

  const downloadConversation = () => {
    const filteredData = inputs
      .filter((input) => input.value !== null && input.value !== '') // Filter out empty values
      .map(({ role, value }) => ({ role, value })) // Map to the desired structure
      .reverse(); // Reverse the filtered array

    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map((item) => ({
        Role: item.role,
        Value: item.value,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Conversation');

    // Generate an Excel file and trigger download
    XLSX.writeFile(workbook, 'conversation.xlsx');
  };

  return (
    <>
      {user && user.id && (
        <div
          className="app"
          onClick={() => {
            setIsHeaderBurgerOpen(false);
          }}
        >
          <NewHeader
            isHeaderBurgerOpen={isHeaderBurgerOpen}
            setIsHeaderBurgerOpen={setIsHeaderBurgerOpen}
            setIsPageEnglish={setIsPageEnglish}
            mainLocation={'playground'}
          />
          <div className="main-page-container">
            <div className={`main-content-container`}>
              <div className={`upper-part-container`}>
                {tabValue === 'edit' && (
                  <div>
                    <div className="new-chat-flex">
                      <div className="page-subheader">{/* Chat/Edit */}</div>
                      <div className="btns-container">
                        {isLoading ? (
                          <FilledBtn
                            style={{ width: 'auto', padding: '0.5em 1em', fontSize: '16px' }}
                            onClick={stopGenerate}
                          >
                            Stop Generate
                          </FilledBtn>
                        ) : (
                          <button
                            className="new-chat-btn"
                            onClick={() =>
                              setInputs([
                                {
                                  role: 'user',
                                  value: '',
                                  isAnswer: false,
                                  isLike: null,
                                  comment: '',
                                  show: true,
                                  toType: false,
                                  key: uuidv4(),
                                  arrOfAnswer: null,
                                  files: [],
                                },
                              ])
                            }
                          >
                            <NewChatIcon />
                            New Chat
                          </button>
                        )}

                        <div onClick={downloadConversation} className="download-chat-container">
                          <DownloadIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {tabValue === 'admin' && <AdminMain />}
              {tabValue === 'voice-analysis' && <VoiceMain />}
              {tabValue === 'agents' && (
                <iframe
                  frameBorder="0"
                  style={{ width: '100%', height: '95%', margin: 0, padding: 0, border: '0px' }}
                  src={langFlowUrl || ''}
                />
              )}
              {tabValue === 'documents' && <DocumentsMain />}
              {tabValue === 'prompts' && <PromptsMain />}
              {tabValue === 'assistant' && (
                <BotsMain
                  isOpenBotCreator={isOpenBotCreator}
                  setIsOpenBotCreator={setIsOpenBotCreator}
                />
              )}
              {tabValue === 'edit' && (
                <>
                  <Chat
                    checkOverflow={checkOverflow}
                    promptsToDb={promptsToDb}
                    documentsToDb={documentsToDb}
                    ref={chatRef}
                  />
                </>
              )}
            </div>

            {tabValue === 'edit' && <MainPanel ref={historyRef} />}

            {tabValue === 'assistant' && isOpenBotCreator && (
              <BotCreatorToolKit
                handleIsCloseToolKit={handleIsCloseToolKit}
                isCloseToolKit={isCloseToolKit}
                setIsCloseToolKit={setIsCloseToolKit}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
